.sign-up-input-field {
	font-family: Poppins; /* Delete after global change */
	width: 100%;
	position: relative;
}

.sign-up-input-field-label-position-inline {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.sign-up-input-field input {
	display: block;
	border-radius: 0.25rem;
	border: 1px solid rgba(153, 153, 153, 0.6);
	background: #fff;
	padding: 0.5rem 0.87rem;
	box-sizing: border-box;
	font-family: inherit;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
}

.sign-up-input-field input:focus {
	border-color: var(--ara-blue);
	outline: none;
}

.sign-up-input-field input::placeholder {
	color: var(--text-grey);
}

/* .sign-up-input-field label {
	color: #000;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	margin-bottom: 0.3rem;
} */

.sign-up-input-field input:disabled {
	color: var(--text-grey);
}

.sign-up-input-field label.label-disabled {
	color: var(--text-grey);
}
.eye-icon {
	position: absolute;
	right: 0.87rem;
	top: 0;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.sign-up-input-field-description {
	margin-top: 0.4rem;
	margin-bottom: 0;
	color: var(--ara-purple);
	font-size: 0.6875rem;
	font-style: normal;
	font-weight: 400;
}
.sign-up-input-field-description a {
	color: var(--ara-purple);
	text-decoration: underline;
}
.sign-up-input-field-description a:hover {
	color: rgba(168, 137, 254, 1);
	transition: color 0.3s ease;
}
.sign-up-input-field .sign-up-input-small {
	width: 5rem;
}
.sign-up-input-field .sign-up-input-medium {
	width: 15rem;
}
.sign-up-input-field .sign-up-input-full-width {
	width: 100%;
}
