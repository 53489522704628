.standard-button-primary,
.standard-button-outline {
	padding: 2px 0.5em 1px;
	border-radius: 0.4em;
	min-width: 5em;
	min-height: 2em;
	text-align: center;
	display: inline-block;
	font-size: 12px;
	font-weight: lighter;
	margin: 0.3em 0.2em;
	font-family: var(--ara-font-family);
	cursor: pointer;
	max-width: fit-content;
	box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.standard-button-primary:hover,
.standard-button-outline:hover {
	box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
	opacity: 0.7;
}

.standard-button-large {
	font-size: 16px;
	padding: 0.5em;
	border-radius: 8px;
}

.standard-button-primary {
	background-color: var(--ara-blue);
	color: white;
	border: none;
}

.standard-button-outline {
	background-color: white;
	color: black;
	border: 1px solid #bec9d9;
}

.standard-button-disabled,
.standard-button-outline:disabled,
.standard-button-primary:disabled {
	background-color: #e0e0e0;
	color: #9e9e9e;
	border: none;
	cursor: default;
}

.new-standard-button-primary,
.new-standard-button-outline {
	padding: 0.6em 2em;
	border-radius: 0 10px;
	min-width: 5em;
	min-height: 2em;
	text-align: center;
	font-family: Poppins, sans-serif;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	cursor: pointer;
	max-width: fit-content;
	border: none;
	transition:
		background-color 0.3s ease,
		color 0.3s ease;
}

.new-standard-button-primary:focus,
.new-standard-button-outline:focus {
	outline: none;
}
.new-standard-button-primary {
	background-color: var(--ara-purple);
	color: rgba(255, 255, 255, 1);
}
.new-standard-button-primary:hover {
	background-color: rgba(168, 137, 254, 1);
	transition:
		background-color 0.3s ease,
		color 0.3s ease;
}
.new-standard-button-outline {
	background-color: rgba(255, 255, 255, 1);
	color: var(--ara-purple);
	border: var(--ara-purple) 1px solid;
}
.new-standard-button-outline:hover {
	background-color: rgba(231, 223, 255, 1);
	transition:
		background-color 0.3s ease,
		color 0.3s ease;
}
.new-standard-button-disabled,
.new-standard-button-outline:disabled,
.new-standard-button-primary:disabled {
	background-color: rgba(204, 204, 204, 1);
	color: rgba(255, 255, 255, 1);
	border: none;
	cursor: default;
	transition:
		background-color 0.3s ease,
		color 0.3s ease;
}
.standard-button-full-width {
	max-width: 100%;
	width: 100%;
}

.standard-button-small {
	font-size: 14px;
	padding: 0.4em;
}
