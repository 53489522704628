.sign-up-select {
	font-family: "Poppins", sans-serif; /*var(--ara-font-family) Delete after global change */
	font-size: 0.875rem;
	/* width: 100%; */
	position: relative;
}

.sign-up-select-label-position-inline {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.sign-up-select button,
.sign-up-select button:hover,
.sign-up-select button:focus,
.sign-up-select button:active,
.sign-up-select .btn-primary:not(:disabled):not(.disabled).active,
.sign-up-select .btn-primary:not(:disabled):not(.disabled):active,
.sign-up-select .show > .btn-primary.dropdown-toggle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 0.25rem;
	border: 1px solid rgba(153, 153, 153, 0.6);
	font-size: inherit;
	font-style: normal;
	font-weight: 400;
	color: var(--text-grey);
	background: #fff;
	padding: 0.5rem 0.87rem;
	width: 100%;
	margin-top: 0.2rem;
	box-sizing: border-box;
	box-shadow: none;
}
.sign-up-select .dropdown-toggle:empty::after {
	margin-left: auto;
}
.sign-up-select button,
.sign-up-select button:focus,
.sign-up-select button:active,
.sign-up-select .btn-primary:not(:disabled):not(.disabled).active,
.sign-up-select .btn-primary:not(:disabled):not(.disabled):active,
.sign-up-select .show > .btn-primary.dropdown-toggle {
	/* border-color: var(--ara-blue); */
	outline: none;
	color: black;
}
.sign-up-select-menu {
	font-size: inherit;
	border-radius: 0.25rem;
	border: 1px solid var(--ara-blue);
	color: black;
	width: 100%;
	padding: 0.5rem 0.87rem;
}
.sign-up-select-menu .dropdown-item {
	font-size: inherit;
	font-family: inherit;
	font-style: normal;
	font-weight: 400;
	/* padding: 0; */
	color: black;
	padding: 0.3rem 0.5rem;
}
.sign-up-select-menu .dropdown-item:active,
.sign-up-select-menu .dropdown-item:focus,
.sign-up-select-menu .dropdown-item:hover {
	background-color: #eee;
	border-radius: 0.25rem;
}

.sign-up-select .sign-up-select-small {
	width: 5rem;
}

.sign-up-select .sign-up-select-medium {
	width: 15rem;
}

.sign-up-select .sign-up-select-full-width {
	width: 100%;
}
