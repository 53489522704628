.confirm-account-form-container {
	padding: 8% 18% 6% 15%;
	width: 100%;
	/* height: 100%; */
	max-height: 100%;
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	gap: 1.5rem;
	overflow: auto;
	scrollbar-color: #d9d9d9 #fff;
	/* scrollbar-width: thin; */
}
.mfa-form-container {
	padding: 8% 18% 6% 15%;
	width: 100%;
	height: 100%;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
	overflow: auto;
	scrollbar-color: #d9d9d9 #fff;
}
.mfa-form-container-medium {
	padding: 25% 18% 25% 15%;
}
.mfa-form-container h2 {
	margin: 0;
	text-align: left;
	color: var(--ara-blue);
	font-family: "Poppins", sans-serif;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
}
.mfa-form-container hr.mfa-form-divider {
	border-top: 3px solid #d9d9d9;
}

.confirm-account-form-container::-webkit-scrollbar {
	width: 8px;
}

.confirm-account-form-container::-webkit-scrollbar-thumb {
	background-color: #d9d9d9;
}

.confirm-account-form-container::-webkit-scrollbar-track {
	background-color: #fff;
}
