.forgot-password-form-container-1,
.forgot-password-form-container-2 {
	width: 100%;

	max-height: 100%;
	display: flex;
	/* justify-content: center; */
	gap: 1.5rem;
	flex-direction: column;
	overflow: auto;
	scrollbar-color: #d9d9d9 #fff;
	/* scrollbar-width: thin; */
}

.forgot-password-form-container-1 {
	padding: 20% 17%;
}

.forgot-password-form-container-2 {
	padding: 4% 17%;
}
