.signup-form-container {
	padding: 5% 18% 6% 15%;
	width: 100%;
	height: max-content;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	gap: 2rem;
	overflow: auto;
	scrollbar-color: #d9d9d9 #fff;
	/* scrollbar-width: thin; */
}
.signup-form-container-code {
	padding: 20% 18% 20% 15%;
	width: 100%;
	height: max-content;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	overflow: auto;
	scrollbar-color: #d9d9d9 #fff;
	/* scrollbar-width: thin; */
}

.signup-form-container::-webkit-scrollbar {
	width: 8px;
}

.signup-form-container::-webkit-scrollbar-thumb {
	background-color: #d9d9d9;
}

.signup-form-container::-webkit-scrollbar-track {
	background-color: #fff;
}
