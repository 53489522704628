.error-boundary-div {
	margin-top: 15vh;
	text-align: center;
	width: 100%;
}

.error-boundary-text {
	text-align: center;
	margin: 10px;
	font-weight: 500;
}

.error-title {
	background-color: var(--ara-pale-purple);
	font-weight: 500;
	border-radius: 5px;
	box-shadow: 0px 0.7px 0px 1.2px rgb(0 0 0 / 25%);
	width: fit-content;
	margin: 10px auto;
	padding: 5px;
}

.error-chat-arrow {
	transform: rotate(180deg);
	margin-left: calc(100% - 100px);
	width: 80px;
	height: 80px;
}

.error-gif {
	margin: 20px;
	width: 250px;
	max-width: calc(100vw - 80px);
	max-height: 30vh;
	object-fit: contain;
}

.error-chat-message-box {
	position: fixed;
	right: 100px;
	bottom: 20px;
	width: 250px;
	max-width: calc(90vw - 100px);
}
