.password-alert {
	padding: 0.25rem 0.5rem;
	font-size: 0.6875rem;
}

.password-alert-title {
	padding: 0;
	margin: 0;
	margin-bottom: 0.15rem;
	font-weight: 500;
}

.password-alert ul {
	margin: 0;
	padding-inline-start: 0;
}
.password-alert li {
	list-style-type: none;
	margin-left: 1rem;
}
.password-alert li::before {
	content: "•";
	display: inline-block;
	width: 20px;
}
.password-alert .valid-requirement {
	list-style-type: none;
}

.password-alert .valid-requirement::before {
	content: "✓ ";
	color: var(--ara-success);
}
.error-text {
	color: var(--ara-error);
}
