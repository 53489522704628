.bolded {
	font-weight: 500;
}

.bold600 .bolded {
	font-weight: 600;
}

.bold700 .bolded {
	font-weight: 700;
}

.normal300 {
	font-weight: 300;
}

.normal400 {
	font-weight: 400;
}

.no-shadow-card {
	box-shadow: none !important;
}

.cover-letter-template {
	padding-top: 0;
	padding-bottom: 0;
	min-height: 400px;
}

.spacing-spaced .candidate-info-row {
	min-height: 55px;
}

.spacing-compact .candidate-info-row {
	min-height: fit-content;
}

.candidate-info-row {
	min-height: 45px;
}

.timeline-card .row {
	margin: 0px;
}

.timeline-main-row {
	width: 100%;
	padding: 12.5px 10px !important;
	background-color: #ffffff00;
	margin-bottom: 20px;
	margin-right: 0;
}

.windows-thicker-text {
	text-shadow: rgba(0, 0, 0, 1) 0.2px 0 0;
}

.noLineMargins {
	margin: 5px 0;
}

.timeline-right-margin {
	padding: 0;
}

.timeline-comments {
	min-width: 100%;
	font-size: 11px;
	margin-top: 10px !important;
	white-space: pre-line;
}

.timeline-title-row {
	font-size: 13px;
	font-weight: 500;
}

.timeline-title-row .text-format {
	padding-bottom: 0.01px;
}

.timeline-info-row {
	font-size: 11px;
	font-weight: 500;
	/* margin: 0 -3px !important; */
}

.timeline-chunk {
	padding: 0;
	margin-right: 0;
	margin-left: 0;
	max-width: 100%;
}

.timeline-left {
	padding-left: 12px;
	padding-right: 0;
	max-width: 120px;
	display: inline-block;
	width: 16.66666667%;
}

.timeline-right {
	padding-right: 0;
	padding-left: 12px;
}

.timeline-mid {
	max-width: 60px;
	padding: 0 12px;
}

.full-height {
	height: 100%;
	padding: 0;
}

.timeline-line {
	height: 100%;
	float: right;
	position: relative;
}

.firstBlock::before {
	content: " ";
	position: absolute;
	border-top-width: 4px;
	border-top-style: solid;
	width: 20px;
	height: 1px;
	top: -0.4px;
	left: 0;
}

.lastBlock::after {
	content: " ";
	position: absolute;
	border-bottom-width: 4px;
	border-bottom-style: solid;
	width: 20px;
	height: 100%;
	bottom: -0.4px;
	left: 0;
}

.CardView.fullWidthCVCard {
	width: 100%;
	padding: 14px 25px 2px !important;
}

.no-padding-col {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.no-padding-col-left {
	padding-left: 0px !important;
	padding-right: 5px !important;
}

.no-padding-col-right {
	padding-left: 5px !important;
	padding-right: 0px !important;
}

.small-padding-col {
	padding-left: 5px !important;
	padding-right: 5px !important;
}

.subtimeline-heading {
	width: 100%;
	text-align: center;
	font-weight: 500;
	padding: 0 20px !important;
}

@media (min-width: 768px) {
	.justify-start-below-md {
		justify-content: flex-start;
	}
}

@media (max-width: 768px) {
	.justify-start-below-md {
		justify-content: center;
	}
}

.template-mode .example-timeline-title {
	color: #999999;
}

.template-mode .CardView {
	box-shadow: none;
	border: 1px solid #dddddd;
}

.ref-sub-array {
	display: inline-block;
	width: 100%;
}

.hidden-card * {
	display: none;
}

#output-body-element .hidden-card.CardView {
	padding: 0 !important;
	margin: 0 !important;
	height: 0 !important;
}

.date-div {
	height: 100%;
	min-height: fit-content;
	text-align: center;
	background-color: transparent;
	font-size: 12px;
	overflow: visible;
}

.date-div .text-format p {
	margin-bottom: 0;
}

.date-inline .editing-text-format {
	min-width: 20px;
}

.date-inline {
	display: flex;
	justify-content: flex-end;
	text-align: right;
	background-color: transparent;
	font-size: 12px;
}

.template-mode .date-inline {
	display: block;
}

.quotedDiv .display-text-format > [data-slate-node^="element"]:not(ul):first-child:before,
.quotedDiv .display-text-format > ul:first-child > li:first-child > :first-child:before,
.quotedDiv .display-text-format > :first-child > .slate-table:before,
.quotedDiv .formatted-placeholder:before {
	position: absolute;
	color: var(--selected-color);
	content: "\201C";
	font-size: 45px;
	margin-left: -22px;
	margin-top: -18px;
	font-family: Georgia, "Times New Roman", Times, serif;
}

.quotedDiv .display-text-format > ul:first-child > li:first-child > :first-child:before {
	margin-left: -36px !important;
	/* need to move quote mark left of bullet in the case the section starts with a bullet */
}

.quotedDiv .display-text-format > :first-child > .slate-table:before {
	/* need to move quote mark inside edit box when section starts with a table */
	margin-left: -19px;
}

.quotedDiv .display-text-format > :last-child > span {
	position: relative;
}

.quotedDiv .display-text-format > :last-child > :not(.slate-table, ul):last-child [data-slate-string^="true"]:after,
.quotedDiv .display-text-format > :last-child > :not(.slate-table, ul):last-child [data-slate-length^="0"]:after,
.quotedDiv .display-text-format > :last-child > .slate-table:after,
.quotedDiv .display-text-format > :last-child > ul > li:last-child [data-slate-string^="true"]:after,
.quotedDiv .display-text-format > :last-child > ul > li:last-child [data-slate-length^="0"]:after,
.quotedDiv .formatted-placeholder:after {
	position: absolute;
	color: var(--selected-color);
	content: "\201D";
	font-size: 45px;
	margin-left: 6px;
	bottom: 0;
	margin-bottom: -2rem;
	font-family: Georgia, "Times New Roman", Times, serif;
}

.quotedDiv .display-text-format > :last-child > .slate-table:after {
	bottom: -25px;
	right: -8px;
}

.quotedDiv .formatted-placeholder {
	position: relative;
}

.spacing-dropdown-menu {
	padding: 0;
	border-radius: 0;
	margin-right: -5px;
	border: 1px solid grey;
	right: 0 !important;
	left: auto !important;
	min-width: 120px;
}

.spacing-dropdown-toggle {
	border-radius: 0;
	margin-right: -5px;
	background-color: transparent !important;
	width: "fit-content";
}

.spacing-dropdown-menu .dropdown-item {
	padding: 0;
}

.spacing-dropdown-toggle::after {
	margin-left: 4px;
}

/*CV Spacing CSS*/

.timeline-title-row {
	padding-bottom: 2.5px;
}

.spacing-compact .timeline-title-row {
	padding-bottom: 0px;
}

.spacing-spaced .timeline-title-row {
	padding-bottom: 5px;
}

#output-body-element .timeline-card {
	padding: 0 10px;
}

#output-body-element .timeline-chunk .row,
#output-body-element .timeline-chunk {
	margin-left: -12px !important;
	margin-right: -12px !important;
}

/* spacing changes */

#output-body-element.spacing-spaced .fullWidthCVCard {
	padding: 12px 20px 1px !important;
	margin: 7px auto;
}

#output-body-element.spacing-compact .fullWidthCVCard {
	padding: 5px 20px 1px !important;
	margin: 0 auto;
}

#output-body-element .fullWidthCVCard {
	padding: 9px 20px 1px !important;
	margin: 4px auto;
}

#output-body-element.spacing-spaced .timeline-main-row,
#output-body-element.spacing-spaced .date-position-box,
.template-placing.spacing-spaced .date-position-box {
	padding-bottom: 13.5px !important;
	margin-bottom: 5px !important;
}

#output-body-element.spacing-compact .timeline-main-row,
#output-body-element.spacing-compact .date-position-box,
.template-placing.spacing-compact .date-position-box {
	padding-bottom: 7px !important;
	margin-bottom: 0px !important;
}

#output-body-element .timeline-main-row,
#output-body-element .date-position-box,
.template-placing .date-position-box {
	padding-bottom: 11px !important;
	margin-bottom: 3px !important;
}

#output-body-element .blank-timeline-template.timeline-main-row {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

#output-body-element.spacing-spaced .timeline-main-row {
	padding-bottom: 13.5px !important;
	margin-bottom: 5px !important;
}

#output-body-element.spacing-compact .timeline-main-row {
	padding-bottom: 0px !important;
	margin-bottom: 5px !important;
}

#output-body-element .timeline-main-row {
	padding-top: 0px !important;
	margin-top: 0px !important;
	padding-bottom: 0px !important;
	margin-bottom: 12px !important;
}

#output-body-element .no-text-timeline-card.timeline-main-row,
#output-body-element.spacing-spaced .no-text-timeline-card.timeline-main-row,
#output-body-element.spacing-compact .no-text-timeline-card.timeline-main-row,
#output-body-element .no-text-timeline-card.timeline-main-row .timeline-comments {
	margin-top: 0 !important;
	padding-top: 0 !important;

	margin-bottom: 0 !important;
	padding-bottom: 0 !important;
}

.date-position-box {
	padding-left: 7.5px;
	margin-left: auto;
	margin-right: auto;
	width: fit-content;
}

.active-circle {
	overflow: auto;
	position: absolute;
	top: 15px;
}

.spacing-icon {
	width: 30px;
	height: 30px;
}

.bordered-icon {
	border: solid 1px #aaaaaa;
	border-radius: 3px;
	height: fit-content;
	background-color: white;
}

.bordered-icon::after {
	content: none !important;
}

#output-body-element .timeline-comments {
	margin-top: 7px !important;
}

#output-body-element.spacing-spaced .timeline-comments {
	margin-top: 10px !important;
}

#output-body-element.spacing-compact .timeline-comments {
	margin-top: 5px !important;
}

#output-body-element h5 {
	margin-top: 0.5rem;
	margin-bottom: 0.4rem;
}

#output-body-element.spacing-spaced h5 {
	/* min-height: 30px; */
	margin-top: 0.75rem;
	margin-bottom: 0.5rem;
}

#output-body-element.spacing-compact h5 {
	min-height: fit-content;
	margin-top: 0.25rem;
	margin-bottom: 0.3rem;
}

.top-timeline-chunk.preset-style-1 {
	margin-top: 7px;
}

.timeline-chunk.preset-style-1 .timeline-right,
.timeline-chunk.preset-style-1 .timeline-left .date-div {
	margin-top: 14px;
}

#output-body-element.spacing-compact .timeline-chunk.preset-style-1 .timeline-right,
#output-body-element.spacing-compact .timeline-chunk.preset-style-1 .timeline-left .date-div {
	margin-top: 7px;
}

#output-body-element.spacing-spaced .timeline-chunk.preset-style-1 .timeline-right,
#output-body-element.spacing-spaced .timeline-chunk.preset-style-1 .timeline-left .date-div {
	margin-top: 19px;
}

#output-body-element.spacing-spaced .top-timeline-chunk.preset-style-1 {
	margin-top: 10px;
}

#output-body-element.spacing-compact .top-timeline-chunk.preset-style-1 {
	margin-top: 4px;
}

[data-slate-node="element"] {
	line-height: var(--local-line-height);
	/* var set to standard at page level, locally set in editor */
}

.spacing-compact [data-slate-node="element"] {
	line-height: calc(var(--local-line-height) * 0.93333);
	/* scale equivalent to reduction from 1.5 to 1.4 */
}

.spacing-spaced [data-slate-node="element"] {
	line-height: calc(var(--local-line-height) * 1.06667);
	/* scale equivalent to increase from 1.5 to 1.6 */
}

.timeline-heading-spacer {
	display: block;
	position: relative;
	width: 100%;
	height: 110px;
	margin-bottom: -110px;
	color: transparent;
	page-break-inside: avoid;
}

#output-body-element.spacing-spaced .timeline-heading-spacer {
	height: 125px;
	margin-bottom: -125px;
}

#output-body-element.spacing-compact .timeline-heading-spacer {
	height: 100px;
	margin-bottom: -100px;
}

.spacing-compact .active-circle {
	top: 10px;
}

.spacing-spaced .active-circle {
	top: 21px;
}

.quotedDiv {
	margin: 0 10px;
}

.template-mode .timeline-card {
	padding: 0 5px;
}

.timeline-main-row-hidden {
	opacity: 0.6;
	border: lightgrey dashed 1px;
}

.timeline-up-down-arrow.disabledButton {
	opacity: 0.5;
	filter: grayscale(70%);
}

.spacer {
	pointer-events: none;
	display: none;
}
