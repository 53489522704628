body {
	/* background-color: #ffffff; */
	background-position: center;
	background-repeat: repeat;
	background-attachment: fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	mix-blend-mode: normal;
	background-image: radial-gradient(46.99% 34.91% at 88.66% 71.09%, #eefef7 26%, rgba(255, 255, 255, 0.1) 100%),
		radial-gradient(54.18% 43.24% at 15.35% 60.99%, #ecfcff 25%, rgba(255, 255, 255, 0.1) 94.5%);
	min-height: 100vh;
	/* min-height: -webkit-fill-available; */
}

.App {
	margin-top: 15px;
	width: 100%;
}

.App .navbar-brand {
	font-weight: 500;
	font-size: xx-large;
}

.AppContainer {
	display: flex;
	flex-direction: column;
}

.main {
	flex: 1 0 92vh;
}
.footer {
	height: 50px;
	flex-shrink: 0;
}

.araLogo {
	width: auto;
	height: 90px;
	margin: 10px;
	margin-bottom: 25px;
}

.App .navitem {
	padding-right: 5mm;
}

.navbar {
	background-color: white;
	color: var(--ara-blue);
	/* margin-bottom: 20px; */
	border-bottom: 1px solid lightgray;
}

.navbar span {
	border-color: transparent;
}

.nav-link {
	color: black !important;
	background-color: white;
	padding: 2px;
	padding-left: 1px !important;
	padding-right: 1px !important;
	margin: 0px 7px;
	font-size: 18px;
}

.blueLink {
	color: var(--ara-blue) !important;
	padding-bottom: 1px;
	transition: color 0.8s 0.5s;
}

.darkLink {
	color: black !important;
}

.nav-disabled {
	pointer-events: none;
	cursor: default;
	color: #999999 !important;
	transition: all 0.8s;
}
.nav-disabled img {
	opacity: 0.6;
}
.nav-disabled span {
	border-bottom: 3px solid transparent;
	color: #999999 !important;
	transition: color 0.8s;
}

.boxTitle {
	font-size: 16px;
	font-weight: 500;
}

.navbar .currentPageUnderline {
	position: relative;
	transition: border-color 0.8s 0.5s;
}

.navbar .currentPageUnderline::after {
	content: "";
	position: absolute;
	bottom: -4px;
	left: 0;
	height: 3px;
	width: 0;
	background: var(--ara-purple);
	animation: revealBorder 0.8s forwards;
}

.nav-item .dropdown-toggle {
	font-size: 15px;
}

.form-control {
	font-weight: 300;
}

.dropdown-item {
	font-family: var(--ara-font-family);
	font-weight: 300;
}

.dropdown-menu {
	max-height: 180px;
	overflow-y: auto;
}

#control-panel-row {
	z-index: 10;
}

.control-bar {
	font-size: 16px;
	min-height: fit-content;
	height: fit-content;
}

.control-bar .col,
.control-bar .col-1,
.control-bar .col-2,
.control-bar .col-3,
.control-bar .col-4,
.control-bar .col-5,
.control-bar .col-6,
.control-bar .col-7,
.control-bar .col-8,
.control-bar .col-9,
.control-bar .col-10,
.control-bar .col-11,
.control-bar .col-12,
.control-bar .col-auto {
	text-align: center;
	padding: 0 5px;
}

.control-bar > .col {
	padding-right: 0;
}

.control-bar .row {
	align-items: center;
	margin: 0;
}

.control-row {
	box-shadow:
		0 1px 0 0 lightgray,
		0 -1px 0 0 lightgray;
	background-color: white;
	width: 100%;
}

.control-bar > * {
	margin-top: auto;
	margin-bottom: auto;
}

.table-control-bar {
	padding: 5px 15px;
	background-color: white;
}

.verifier-bar {
	margin-top: -20px;
}

.control-bar,
.verifier-bar {
	background-color: white;
	/* border-bottom: 1px solid lightgray; */
	box-shadow: 0 1px 0 0 lightgray;
	justify-content: space-between;
	position: sticky;
	top: 0;
	z-index: 2;
	margin-left: 0;
	margin-right: 0;
}

.cvdisplay .control-bar {
	background-color: transparent;
	box-shadow: none;
}

@-webkit-keyframes borderBlink {
	from {
		opacity: 0;
	}

	20% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	65% {
		border-color: var(--ara-gold);
	}

	70% {
		border-color: var(--ara-gold);
	}

	to {
		border-color: transparent;
	}
}

@keyframes borderBlink {
	from {
		opacity: 0;
	}

	10% {
		opacity: 0;
	}

	40% {
		opacity: 1;
	}

	55% {
		border-color: var(--ara-gold);
	}

	60% {
		border-color: var(--ara-gold);
	}

	to {
		border-color: transparent;
	}
}

@keyframes revealBorder {
	from {
		opacity: 0;
		width: 0;
	}
	to {
		opacity: 1;
		width: 100%;
	}
}

.borderFlash {
	border-width: 2px;
	border-style: solid;
	border-color: transparent;
	-webkit-animation: borderBlink;
	-webkit-animation-duration: 2s;
	animation: borderBlink;
	animation-duration: 1.5s;
	animation-timing-function: ease-in-out;
}

.goldText {
	color: var(--ara-gold);
}

.greenText {
	color: var(--ara-green);
}

.redText {
	color: var(--ara-red);
}

.blueText {
	color: var(--ara-blue);
}

.purpleText {
	color: var(--ara-purple);
}

.purpleTextAlways,
.purpleTextAlways:active,
.purpleTextAlways:focus {
	color: var(--ara-purple);
}

.greyText {
	color: var(--text-grey);
}

.darkGreyText {
	color: #444444;
}

.icon-down-arrow,
.icon-up-arrow {
	--arrow-size: 5.5px;
}

.icon-down-arrow {
	border-top: var(--arrow-size) solid #aaaaaa;
	border-right: var(--arrow-size) solid transparent;
	border-bottom: 0;
	border-left: var(--arrow-size) solid transparent;
}

.icon-up-arrow {
	border-bottom: var(--arrow-size) solid #aaaaaa;
	border-right: var(--arrow-size) solid transparent;
	border-top: 0;
	border-left: var(--arrow-size) solid transparent;
}

textarea.input-box:focus,
input.input-box:focus {
	outline: none;
	border: 2px solid var(--ara-blue);
}

textarea.input-box,
input.input-box {
	border-radius: 3px;
	outline-width: 0px;
	border-width: 0.625px;
}
