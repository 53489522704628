.AraTooltip > .tooltip-inner {
	color: #cccccc;
	background-color: var(--ara-dark);
	opacity: 1;
}

.AraTooltip > .tooltip-inner b {
	color: white;
}

.AraTooltip.tooltip.show {
	opacity: 1;
}

.AraTooltip.bs-tooltip-right > .arrow::before {
	border-right-color: var(--ara-dark);
}

.AraTooltip.bs-tooltip-bottom > .arrow::before {
	border-bottom-color: var(--ara-dark);
}

.AraTooltip.bs-tooltip-top > .arrow::before {
	border-top-color: var(--ara-dark);
}

.AraTooltip.bs-tooltip-left > .arrow::before {
	border-left-color: var(--ara-dark);
}

.tooltip-wrap-highlighted > * {
	border: 1px solid red !important;
}

.AraTooltip {
	pointer-events: none;
}
