.sign-up-form {
	height: 100%;
}

.sign-up-form form {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	max-height: fit-content;
	position: relative;
	justify-content: space-between;
	gap: 0.8rem;
}

.sign-up-form h1 {
	margin: 0;
	text-align: left;
	padding: 5px 0;
	font-family: "Raleway", sans-serif;
	color: #000;
	font-size: 2rem;
	font-style: normal;
	font-weight: 700;
}

.sign-up-form h2 {
	margin: 0;
	text-align: left;
	color: var(--ara-blue);
	font-family: "Poppins", sans-serif;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
}
.sign-up-form h3 {
	margin: 0;
	text-align: left;
	color: var(--ara-blue);
	font-family: "Poppins", sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
}

.sign-up-form label {
	color: #000;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	margin-bottom: 0.3rem;
}
