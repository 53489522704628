.pop-up-message {
	display: flex;
	align-items: center;
	gap: 1.5rem;
	padding: 1rem 2rem;
	border: none;
	background-color: #1e1751;
	color: white;
	font-size: 1.5rem;
	font-weight: 500;
	border-radius: 4px;
	width: fit-content;
	position: fixed;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
}
.pop-up-message-hidden {
	display: none;
}

.pop-up-message-visible {
	animation: fade-out 3s ease-in-out;
	animation-fill-mode: forwards;
}

@keyframes fade-out {
	0% {
		opacity: 100%;
	}
	80% {
		opacity: 100%;
	}
	100% {
		opacity: 0%;
	}
}
.pop-up-message svg {
	height: 3.5rem;
	width: 3.5rem;
}
