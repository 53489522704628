.ara-modal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(3px);
	min-width: 300px;
	min-height: 300px;
}

.ara-modal > * {
	pointer-events: none;
}

.ara-modal button,
.ara-modal .clickable {
	pointer-events: initial;
}

.ara-modal-close {
	position: absolute;
	top: 0;
	right: 0;
	height: 40px;
	width: 40px;
	margin: 8px;
	background-color: transparent;
	cursor: pointer;
	border-radius: 50%;
	/* border: 1px solid black; */
}
.ara-modal-close > img {
	border-radius: 50%;
}

.working-space {
	position: relative;
	min-height: fit-content;
}
