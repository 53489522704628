.spinning {
	margin-right: 7px;
	top: 2px;
	animation: spin 1s infinite linear;
}

@keyframes spin {
	from {
		transform: scale(1) rotate(0deg);
	}

	to {
		transform: scale(1) rotate(360deg);
	}
}

.LoaderButton {
	font-family: var(--ara-font-family);
	min-width: 100px;
	max-width: 200px;
	border-radius: 8mm;
	padding-left: 10px;
	padding-right: 10px;
	box-shadow: 0px 0.7px 0px 1.2px rgb(0 0 0 / 25%);
	margin-top: 5px;
	margin-left: 5px;
	margin-right: 5px;
	text-align: center;
}

.LoaderButton,
.LoaderButton:focus,
.LoaderButton:active {
	background-color: #f8f9fa;
}
.LoaderButton:disabled {
	cursor: not-allowed;
}

.SquarerLoaderButton,
.SquarerLoaderButton:focus {
	border-radius: 8px;
	/* height: calc(2rem + 6px); */
}

.LoaderButton .lbContent {
	min-width: 50px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.smallButton {
	font-size: 11px;
	/* max-height: 30px; */
	min-width: 86px;
	padding-left: 15px;
	padding-right: 15px;
	margin: 0;
}

.mediumButton {
	font-size: 12px;
	max-height: 40px;
	min-width: 20px;
	padding-left: 8px;
	padding-right: 8px;
	display: flexbox;
}

.LoaderButton.btn-aralight {
	background-color: white;
	color: black;
}

.LoaderButton.btn-aradark {
	background-color: var(--ara-dark);
	color: white;
}

.LoaderButton.btn-aragold {
	background-color: var(--ara-gold);
	color: white;
	border-width: 0;
}

.disabledButton {
	opacity: 0.7;
	cursor: not-allowed;
}

.btn {
	font-weight: 300;
}

.loader-fallback {
	width: 100%;
	opacity: 0.3;
}

.loader-fallback > div {
	margin: 100px auto;
	width: fit-content;
	text-align: center;
	animation: appearAfter 0.8s linear;
}

.loader-fallback .spinning {
	width: 40px;
	height: 40px;
	animation: spin 1.2s infinite linear;
}

.loader-fallback h5 {
	margin-top: 10px;
}

@keyframes appearAfter {
	from {
		opacity: 0;
	}

	80% {
		opacity: 0;
	}

	to {
		opacity: 0.3;
	}
}

.image-button {
	width: fit-content;
	position: relative;
}

.image-button-centered {
	margin: auto;
}

.image-button .spinning {
	color: white;
	position: absolute;
	top: calc(50% - 0.5em);
	left: calc(50% - 0.5em);
}

.image-button-label {
	font-size: 11px;
	text-align: center;
}

.blue-loader-button {
	background-color: var(--ara-blue);
	color: white;
	border-width: 0;
	border-radius: 3px;
	min-width: fit-content;
	min-height: fit-content;
	margin: auto;
}

.blue-loader-button .lbContent {
	padding: 0;
	min-width: fit-content;
}
