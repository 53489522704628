.card-sign-up {
	position: relative;
	display: flex;
	border-radius: 0.5rem;
	background: #fff;
	box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.15);
	height: fit-content;
	/* max-height: 90vh; */
}
.card-sign-up-small {
	width: min(100%, 58rem);
	max-height: min(34rem, 90vh);
}
.card-sign-up-medium {
	width: min(100%, 80rem);
	max-height: min(40rem, 90vh);
}
.card-sign-up-large {
	width: min(100%, 86rem);
	max-height: min(46rem, 90vh);
}
.blue-logo {
	position: absolute;
	top: 36px;
	right: 43px;
	width: 29px;
	height: 35px;
}
.card-sign-up-image-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.card-sign-up-content {
	width: 100%;
	max-height: 100%;
}

@media screen and (max-width: 768px) {
	.card-sign-up-image-container {
		display: none;
	}
}
