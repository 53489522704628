.login-form-container {
	padding: 13% 18% 13% 15%;
	width: 100%;
	height: 100%;
	max-height: 100%;
	display: flex;
	justify-content: space-between;
	gap: 1.5rem;
	flex-direction: column;
	overflow: auto;
	scrollbar-color: #d9d9d9 #fff;
	/* scrollbar-width: thin; */
}
